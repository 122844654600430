<script setup lang="ts">
import { onMounted, onBeforeMount } from "vue";
import { useStore } from 'vuex';

const store = useStore();

onBeforeMount(() => {
  const script = document.createElement("script");
  script.src = "https://do.featurebase.app/js/sdk.js";
  script.id = "featurebase-sdk";
  document.head.appendChild(script);
});

onMounted(() => {
  const win: any = window;
  if (typeof win.Featurebase !== "function") {
    win.Featurebase = function () {
      (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
    };
  }

  const currentUser = store.state.auth.currentUser;

  win.Featurebase(
    "identify",
    {
      organization: "InsurMagic",
      email: currentUser.email,
      name: currentUser.name,
      id: currentUser.id.toString(),
      locale: "de",
    },
    (err) => {
      if (err) {
        // console.error(err);
      } else {
        // console.log("Data sent successfully!");
      }
    }
  );
});
</script>

<template>
  <main>
  </main>
</template>