// Import any necessary dependencies here

const state = {
  uploadHandler: null,
  audioTranscriptionEnabled: false,
  isAudioTranscriptionInitialized: false,
};

const mutations = {
  SET_UPLOAD_HANDLER($state, handler) {
    $state.uploadHandler = handler;
  },
  UPLOAD_ATTACHMENTS($state, attachments) {
    if ($state.uploadHandler) {
      $state.uploadHandler(attachments);
    }
  },
  SET_AUDIO_TRANSCRIPTION_ENABLED($state, isEnabled) {
    $state.audioTranscriptionEnabled = isEnabled;
  },
  SET_AUDIO_TRANSCRIPTION_INITIALIZED($state, isInitialized) {
    $state.isAudioTranscriptionInitialized = isInitialized;
  },
};

const actions = {
  uploadAttachmentsToMvp({ commit }, { attachments, newFileName }) {
    commit('UPLOAD_ATTACHMENTS', { attachments, newFileName });
  },

  registerUploadHandler({ commit }, handler) {
    commit('SET_UPLOAD_HANDLER', handler);
  },

  unregisterUploadHandler({ commit }) {
    commit('SET_UPLOAD_HANDLER', null);
  },

  async updateAudioTranscriptionSetting({ commit, dispatch }, isEnabled) {
    try {
      // Use dispatch instead of this.$store.dispatch
      await dispatch(
        'accounts/update',
        {
          audio_message_transcription_enabled: isEnabled.toString(),
        },
        { root: true }
      );
      commit('SET_AUDIO_TRANSCRIPTION_ENABLED', isEnabled);
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating audio transcription setting:', error);
      return false;
    }
  },

  async initializeAudioTranscriptionSetting({
    commit,
    state: $state,
    rootGetters,
  }) {
    if ($state.isAudioTranscriptionInitialized) {
      return;
    }

    try {
      const accountId = rootGetters.getCurrentAccountId;
      const account = rootGetters['accounts/getAccount'](accountId);
      const isEnabled =
        account.custom_attributes?.audio_message_transcription_enabled ===
        'true';

      commit('SET_AUDIO_TRANSCRIPTION_ENABLED', isEnabled);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error initializing audio transcription setting:', error);
    } finally {
      commit('SET_AUDIO_TRANSCRIPTION_INITIALIZED', true);
    }
  },
};

const getters = {
  getAudioTranscriptionEnabled: $state => $state.audioTranscriptionEnabled,
  isAudioTranscriptionInitialized: $state =>
    $state.isAudioTranscriptionInitialized,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
