<script>
import FeedbackSDK from './FeedbackSDK.vue';

export default {
  components: {
    FeedbackSDK,
  },
  data() {
    return {
      hasClickedFeedback: false,
    };
  },
  created() {
    this.hasClickedFeedback =
      localStorage.getItem('hasClickedFeedback') === 'true';
  },
  methods: {
    handleClick() {
      this.hasClickedFeedback = true;
      localStorage.setItem('hasClickedFeedback', 'true');
      this.$emit('click');
    },
  },
};
</script>

<template>
  <FeedbackSDK />
  <div class="relative">
    <a
      v-tooltip.right="$t('FEEDBACK.TITLE')"
      data-featurebase-link
      href="https://feedback.insurmagic.de/de"
      target="_blank"
      rel="noopener noreferrer"
      class="text-slate-700 dark:text-slate-100 w-11 h-11 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
      @click="handleClick"
    >
      <fluent-icon icon="comment-add" class="w-6 h-6" />
      <span class="sr-only">{{ $t('FEEDBACK.TITLE') }}</span>
    </a>

    <template v-if="!hasClickedFeedback">
      <div
        class="radar-ping-animation absolute top-0 right-0 -mt-1 -mr-1 rounded-full w-3 h-3 bg-woot-500 dark:bg-woot-500"
      />
      <div
        class="absolute top-0 right-0 -mt-1 -mr-1 rounded-full w-3 h-3 bg-woot-500 dark:bg-woot-500 opacity-50"
      />
    </template>
  </div>
</template>

<style scoped>
@tailwind components;
@layer components {
  @keyframes ping {
    75%,
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  .radar-ping-animation {
    animation: ping 1s ease infinite;
  }
}
</style>
